<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DETAILS PRODUCTS</title>
    <section class="content-header">
      <h1>
        Update Product Details
        <br />
        <h4>Please edit the detailed product transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Transaction Details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Detailed Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="produkdno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxprodukdno"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Part Code</label>
                    <input
                      type="text"
                      v-model="kodepart"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Part Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getpart()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="qtyd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <!-- <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="satuand"
                    autocomplete="off"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="catatand"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updtdt",
  data() {
    return {
      loading: false,
      produkdno: "",
      idxprodukdno: "",
    //   kodebarang: "",
    //   namabarang: "",
      qtyd: "",
      catatand: "",
      kodepart: "",
      namapart: "",
    //   satuand: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
      async getpart() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.kodepart;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code not found",
              showConfirmButton: false
            });
          } else {
            this.namapart = resp.data.data.name;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        // "produk_d_bom/getproduk_d_bombyid?id=" +
        "produk_d/getproduk_dbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprodukdno = resp.data.data.id;
          this.produkdno = resp.data.data.nomor_produk;
          this.qtyd = resp.data.data.qty;
        //   this.satuand = resp.data.data.unit;
          this.kodepart = resp.data.data.part_no;
          this.namapart = resp.data.data.part_name;
          this.catatand = resp.data.data.notes;

          const urlAPIgetsup =
            this.$apiurl +
            "part_component/getpart_componentbypart_no?part_no=" +
            this.kodepart;

          axios.get(urlAPIgetsup, { headers }).then((respo) => {
            if (respo.data.pesan == "Data not found") {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Sorry, Part No. not found",
                showConfirmButton: false
              });
              this.namapart = "";
            } else {
              this.namapart = respo.data.data.part_name;
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var nop = this.produkdno;
      var kodep = this.kodepart;
      var namap = this.namapart;
      var qtydp = this.qtyd;
      var catatanp = this.catatand;
      var idx = this.idxprodukdno;
    //   var sat = this.satuand;
      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodep == "" || namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name / Part No. can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtydp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
    //   } else if (sat == "") {
    //     swal({
    //       icon: "warning",
    //       title: "Warning",
    //       text: "Unit can not be empty",
    //       showConfirmButton: false
    //     });
    //     this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.produkdno,
          part_no: this.kodepart,
          notes: this.catatand,
          qty: this.qtyd,
          kode_user: kodeuser,
          part_name: this.namapart,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/produk_d/updateproduk_d/"+ idx;
        // const urlAPIUpdate = this.$apiurl + "produk_d_bom/updateproduk_d_bom/" + idx;
        const urlAPIUpdate = this.$apiurl + "produk_d/updateproduk_d/" + idx;
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
